import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { locale as en_US } from '../config/i18n/en-US'
import { locale as zh_TW } from '../config/i18n/zh-TW'
import { locale as zh_CN } from '../config/i18n/zh-CN'

Vue.version = "2.6.14";
Vue.use(VueI18n)
let messages = {}
messages = { en_US, zh_TW, zh_CN }
const lang = 'zh_TW'
const i18n = new VueI18n({
  locale: lang,
  messages,
})
export default i18n